<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>代理商管理</p>
			<i>/</i>
			<p @click="jumpPage">代理商角色</p>
			<i>/</i>
			<p class="active">代理商角色编辑</p>
		</nav>
	
		<div class="page_edit">
	
			<h4 class="h4">基础信息</h4>
	
			<div class="page_edit_form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="" prop="name">
						<p class="form_label_p">代理商角色名称</p>
						<el-input class="input_long" v-model="ruleForm.name" placeholder="请输入代理商名称(文本20)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="sort">
						<p class="form_label_p">排序</p>
						<el-input class="input_long" v-model="ruleForm.sort" placeholder="请输入排序(整数越小越靠前)">
						</el-input>
					</el-form-item>
					<div class="relation_goods">
						<p class="title">代理产品</p>
						<div class="goods_option" v-if="goodsData.length > 0">
							<div class="goods_option_item" v-for="(item, s) in goodsData" :key="s">
								<div class="one_select_all">
									<el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll"
										@change="checked => handleCheckAllChange(checked, item)">{{item.cat_name}}</el-checkbox>
								</div>
								<div class="two_single_choice" v-if="item.products_list.length">
									<el-checkbox-group v-model="item.checkedCities" @change="checked => handleCheckedCitiesChange(checked, item)">
										<el-checkbox v-for="(city, index) in item.products_list" :label="city.id" :key="index">{{city.title}}
										</el-checkbox>
									</el-checkbox-group>
								</div>
							</div>
						</div>
						<div v-else class="no_data">
							<p>—— ——</p>
						</div>
					</div>
					<div class="relation_goods">
						<p class="title">代理套餐</p>
						<div v-if="packageData.length > 0" class="goods_option_item">
							<el-checkbox-group v-model="checkList">
								<el-checkbox v-for="(item, index) in packageData" :key="index" :label="item.id">{{item.combo_name}}</el-checkbox>
							</el-checkbox-group>
						</div>
						<div v-else class="no_data">
							<p>—— ——</p>
						</div>
					</div>
					<el-form-item label="" prop="limit_num">
						<p class="form_label_p">企业用户数</p>
						<el-input class="input_long" v-model="ruleForm.limit_num" placeholder="请输入正整数">
						</el-input>
						<span class="form_label_span">个</span>
					</el-form-item>
					<el-form-item label="" prop="alliance_fee">
						<p class="form_label_p">加盟费</p>
						<el-input class="input_long" v-model="ruleForm.alliance_fee" placeholder="最多保留2位小数">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="status">
						<p class="form_label_p">状态</p>
						<el-radio-group v-model="ruleForm.status">
							<el-radio label="1">激活</el-radio>
							<el-radio label="0">冻结</el-radio>
						</el-radio-group>
					</el-form-item>
					<div class="el-form-item_last"></div>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation">
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" v-if="modifyBtn" @click="createForm('ruleForm')">保存</p>
							<p class="p2" v-else @click="editForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>
	
		</div>
	
	</div>
</template>

<script>
	
	export default {
		name: 'agentRoleEdit',
		data() {
			return {
				ruleForm: {
					name: '',
					status: '1', // 状态
					sort: '',  // 排序
					limit_num: '',  // 企业用户数
					alliance_fee: '', // 加盟费
				},
				rules: {
					name: [{
						required: true,
						message: '名称不能为空',
						trigger: 'blur'
					}],
					sort: [{
						pattern: /^\+?[1-9][0-9]*$/,
						message: '只能是非0的正整数',
					}],
					limit_num: [
						{
							required: true,
							message: '企业用户数不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^\+?[1-9][0-9]*$/,
							message: '只能是非0的正整数',
						}
					],
					alliance_fee: [{
							required: true,
							message: '单价不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^[0-9]+(.[0-9]{1,2})?$/,
							message: '最多两位小数的数字'
						}
					],
				},
				checkAll: false,
				checkedCities: [],
				isIndeterminate: true,
				modifyBtn: true, // 默认创建
				checkList: [],  // 代理套餐数组
				goodsData: [],  // 套餐关联平台级产品
				packageData: [],  // 代理套餐列表
				chooseData: [],  //后台返回已选产品及数字
			}
		},
		components: {
			
		},
		created() {
			if (this.$route.query.id) {
				this.modifyBtn = false;
				
				// 获取详情做修改
				this.getAgenInfo()
			}
			else{
				// 获取代理产品
				this.getAgenGoodsList()
			}
			
			// 获取代理套餐
			this.getPackageList();
		},
		methods: {
			
			// 获取代理产品
			getAgenGoodsList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}combo/productslist`,
					data: {}
				}).then( res => {
					if(res.code == '0'){
						this.goodsData = res.data.map( item => {
							this.$set(item, 'checkAll', false);
							this.$set(item, 'checkedCities', []);
							this.$set(item, 'isIndeterminate', true);
							return item;
						});
						
						if(this.chooseData.length > 0 && this.modifyBtn == false){
							
							this.chooseData.forEach( choose => {
								this.goodsData.forEach( (citie, i) => {
									if(citie.products_list.length > 0){
										citie.products_list.forEach( item => {
											if(item.id == choose){
												this.goodsData[i].checkedCities.push(item.id)
											}
										})
									}
								})
							})
							
							
						}
					}
				})
			},
			
			// 获取代理套餐
			getPackageList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}combo/combolist`,
					data: {}
				}).then( res => {
					if(res.code == 0){
						this.packageData = res.data;
					}
				})
			},
			
			// 获取详情做修改
			getAgenInfo(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}agentrole/detail`,
					data: {
						id: this.$route.query.id,
					}
				}).then( res => {
					if(res.code == '0'){
						
						this.ruleForm.name = res.data.role_name;
						this.ruleForm.limit_num = res.data.limit_num;
						this.ruleForm.sort = res.data.sort;
						this.ruleForm.status = res.data.status;
						this.ruleForm.alliance_fee = res.data.alliance_fee;
						this.checkList = JSON.parse(res.data.agent_combo_ids);
						this.chooseData = JSON.parse(res.data.agent_product_ids);
						
						
						// 获取代理产品
						this.getAgenGoodsList()
					}
				})
			},

			// 全选
			handleCheckAllChange(val, item) {
				let arr = [];
				item.products_list.forEach( s => {
					arr.push(s.id)
				})
				item.checkedCities = val ? arr : [];
				item.isIndeterminate = false;
			},
			
			// 单选
			handleCheckedCitiesChange(value, item) {
				let checkedCount = value.length;
				item.checkAll = checkedCount === item.products_list.length;
				item.isIndeterminate = checkedCount > 0 && checkedCount < item.products_list.length;
			},

			// 创建
			createForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						let arr = []
						this.goodsData.forEach( item => {
							if(item.checkedCities.length > 0){
								item.checkedCities.forEach( child => {
									arr.push(child)
								})
							}
						})
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}agentrole/add`,
							data: {
								role_name: this.ruleForm.name,
								sort: this.ruleForm.sort,
								limit_num: this.ruleForm.limit_num,
								alliance_fee: this.ruleForm.alliance_fee,
								status: this.ruleForm.status,
								agent_product_ids: JSON.stringify(arr),
								agent_combo_ids: JSON.stringify(this.checkList),
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '创建成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 修改
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						let arr = []
						this.goodsData.forEach( item => {
							if(item.checkedCities.length > 0){
								item.checkedCities.forEach( child => {
									arr.push(child)
								})
							}
						})
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}agentrole/edit`,
							data: {
								id: this.$route.query.id,
								role_name: this.ruleForm.name,
								sort: this.ruleForm.sort,
								limit_num: this.ruleForm.limit_num,
								alliance_fee: this.ruleForm.alliance_fee,
								status: this.ruleForm.status,
								agent_product_ids: JSON.stringify(arr),
								agent_combo_ids: JSON.stringify(this.checkList),
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},

			// 头部跳转页面
			jumpPage() {
				this.$router.go(-1);
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}

	.relation_goods {
		padding: 24px;
		background-color: #fff;

		.title {
			font-size: 14px;
			line-height: 40px;
			color: rgba(0, 0, 0, .85);
		}

		.goods_option_item {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 8px;

			.one_select_all,
			.two_single_choice {
				padding: 12px;
				border: 1px solid rgba(0, 0, 0, 0.2);
			}

			.two_single_choice {
				border-left: none;
			}

		}
	}
</style>
